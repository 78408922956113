/*******************************
    User Variable Overrides
*******************************/

.ui.cards > .card, .ui.card {
    padding: 20px;
    border: 0px solid #fff;
}

.ui.centered.card {
    margin-top: 25px;
    margin-bottom: 25px;
}

.ui.cards > .card > :last-child,  .ui.card > :last-child {
    border-radius: 0.28571429rem 0.28571429rem 0.28571429rem 0.28571429rem !important;
}

.ui.cards > .card > :first-child, .ui.card > :first-child {
    border-radius: 0.28571429rem 0.28571429rem 0.28571429rem 0.28571429rem !important;
}