/*******************************
        User Overrides
*******************************/

/* Menu Items */

.ui.selection.dropdown .menu > .item {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.ui.dropdown .menu > .item:hover {
    background: rgb(234 239 255) !important;
    color: rgb(10 88 255)  !important;
    border-radius: 10px;
}

.ui.selection.dropdown .menu {
    border-radius: 10px !important;
    border-top-width: 1px !important;
}

.ui.selection.active.dropdown .menu {
    border-color: #e4e1ef !important;
    margin-top: 10px;
    padding: 7px !important;
}

ui.selection.active.dropdown .menu:hover {
        border-color:#e4e1ef;
}

.ui.selection.active.dropdown:hover {
        border-color: #e4e1ef;
}

.ui.selection.active {
        border-color:#e4e1ef;
}


/* Selected Item */

.ui.dropdown.selected, .ui.dropdown .menu .selected.item {
    background: rgb(242 245 255) !important;
    color: rgb(28 92 234) !important;
    border-radius: 10px;
}

/* Input */

.ui.selection.active.dropdown {
    border-color: #5f90ff;
    box-shadow: 0px 0px 1px 3px #94b5ff5e;
}

.ui.active.selection.dropdown {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.ui.selection.active.dropdown:hover {
    border-color: #5f90ff;
    box-shadow: 0px 0px 1px 3px #94b5ff5e;
}

.ui.active.selection.dropdown:hover {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

/* Icon */

.ui.dropdown > .dropdown.icon:before {
    content: '\f0d7';
    color: #747086 !important;
}

ui.active.selection.dropdown > .dropdown.icon, .ui.visible.selection.dropdown > .dropdown.icon {
    color: #747086 !important;
}

/* Scrollbar */

body ::-webkit-scrollbar-track {
    background: rgba(0,0,0,0) !important;
    border-radius: 10px !important;
}

body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgb(242 245 255);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}