/*******************************
         Site Overrides
*******************************/
.ui.form .fields .field .ui.input input, .ui.form .field .ui.input input {
    background: #F2F2F2;
    color: #333;
    border: 0px solid #fff;
    -webkit-tap-highlight-color: #EDEDED;
    width: 100%;
}
