/*******************************
         Site Overrides
*******************************/
.ui.input > input {
    background: #F2F2F2;
    color: #333;
    border: 0px solid #fff;
    -webkit-tap-highlight-color: #EDEDED;
}

 .ui.form input[type="text"] {
     background: #F2F2F2 !important;
    color: #333 !important;
    border: 0px solid #fff !important;
    -webkit-tap-highlight-color: #EDEDED !important;
 }

 .ui.form textarea {
    background: #F2F2F2 !important;
    color: #333 !important;
    border: 0px solid #fff !important;
    -webkit-tap-highlight-color: #EDEDED !important;
    font-family: 'Lato';
}